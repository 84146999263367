

























































  import Vue from 'vue';
  import Component from 'vue-class-component';
  import moment from 'moment';
  import { INSURANCES, INSURANCE_TYPE, PRODUCTS } from '@/constant/Enums';
  import { Prop } from 'vue-property-decorator';
  import { Action, State } from 'vuex-class';
  import { PlateModel } from '@/store/product/types';
  import FormCompareMobilModel, { ProductMobilState } from '@/store/product/mobil/types';
  import { ServerResponse } from '@/services/response.types';

  @Component({
    name: 'compareCar',
    components: {},
  })
  export default class compareCar extends Vue {
    @Prop({ default: 'BANDINGKAN SEKARANG' }) buttonTitle!: string;
    @Prop({ default: 'Asuransi Mobil' }) title!: string;
    @Prop({ default: '' }) desciption!: string;

    @Action('GetMobil', { namespace: 'product/mobil' }) GetMobil!: (params?: any) => Promise<ServerResponse>;
    @Action('GetData', { namespace: 'product/mobil' }) GetData!: (form: FormData) => Promise<ServerResponse>;

    @State('plates', { namespace: 'product/mobil' }) plates!: PlateModel[];
    @State((state: ProductMobilState) => state.title.HTMLtoString(), { namespace: 'product/mobil' }) _title!: string;
    @State((state: ProductMobilState) => state.content.HTMLtoString(), { namespace: 'product/mobil' }) content!: string;
    @State((state: ProductMobilState) => state.formCompare, { namespace: 'product/mobil' }) form!: FormCompareMobilModel;

    public INSURANCE_TYPE = INSURANCE_TYPE;

    public get titleMutator(): string {
      return this.title ?? this._title;
    }

    public get descriptionMutator(): string {
      return this.desciption ?? this.content;
    }

    public get optionPlateNumber(): { text: string; value: any }[] {
      return this.plates.map((v) => Object.assign({}, { text: `${v.plate_number_code} ${v.plate_number_city}`, value: v.plate_number_id }));
    }
    public optionTypeInsurances = INSURANCES;

    public get optionsYear(): number[] {
      const years = [];
      const currentYear = moment().get('year');
      for (let i = 0; i < 20; i++) {
        years.push(currentYear - i);
      }
      return years;
    }

    public created(): void {
      this.GetMobil().then((res) => {
        let history = localStorage.getItem('car-history');
        if (typeof history === 'string') {
          this.$store.state.product.mobil.formCompare = JSON.parse(history);
        }
      });
    }
    public mounted(): void {
      // on componenet mounted
    }

    /**
     * OnSubmit
     */
    public OnSubmit(ev: Event) {
      localStorage.setItem('car-history', JSON.stringify(this.form));
      this.GetData(new FormData(ev.target as HTMLFormElement)).then((res) => {
        if (this.$route.name !== 'compare-' + PRODUCTS[1].name.toLowerCase().replace(' ', '-')) {
          this.$router.push({ name: 'compare-' + PRODUCTS[1].name.toLowerCase().replace(' ', '-') });
        }
      });
    }
  }
